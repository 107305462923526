import React, {useState} from 'react';
import TableContainer from '../../../UI/Table/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import {IPaginatable} from '../../../types/app/common/Paginatable';
import {IClient} from '../../../types/app/client/Client';
import moment from 'moment';
import {IClientRank} from '../../../types/app/client/ClientRank';
import ShowButton from '../../../UI/Button/ShowButton';
import {getStatusNameByClientId} from '../../../utils/clientUtils';
import {RouteList} from '../../../http/RouteList';
import {TableHelper} from '../../../helpers/TableHelper';
import {Grid} from '@mui/material';
import DeleteButton from '../../../UI/Button/DeleteButton';
import Confirm from '../../Common/Modal/Confirm';
import {messages} from '../../../notifications/messages';
import {useNotifications} from '../../../hooks/useNotifications';
import {ClientService} from '../../../http/client/ClientService';

interface IClientTabContentDataProps extends IPaginatable<IClient>{
  ranks: IClientRank[]
  request: () => void
}

const ClientsTabContentData = (props: IClientTabContentDataProps) => {
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [clientId, setClientId] = useState<number>(null);

  const message = useNotifications();

  const closeConfirm = () => {
    setShowConfirm(false);
  };

  const deleteClientHandler = async (): Promise<void> => {
    setIsLoading(true);

    try {
      await ClientService.deactivateClient({id: clientId});
      message.success(messages.success.clientDeleteOperation);
      props.request()
    }
    catch {
      message.error(messages.errors.deleteClientError);
    }
    finally {
      setClientId(null)
      setIsLoading(false);
      closeConfirm();
    }
  };

  return (
    <TableContainer boxprops={{ style: {top: '-16px'}}}>
      <Confirm
        open={showConfirm}
        close={closeConfirm}
        loading={isLoading}
        submit={deleteClientHandler}
        text={`Вы действительно хотите удалить данную учетную запись? Данное действие нельзя будет отменить.`}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell variant="head" width={100}>№</TableCell>
            <TableCell variant="head" width={80}>Идентификатор Пользователя</TableCell>
            <TableCell variant="head" width={250}>ФИО</TableCell>
            <TableCell variant="head" width={235}>Email</TableCell>
            <TableCell variant="head" width={235}>Телефон</TableCell>
            <TableCell variant="head" width={150}>Дата регистрации</TableCell>
            <TableCell variant="head" width={100}>Дата последней авторизации</TableCell>
            <TableCell variant="head" width={150}>Статус</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.items.map((client, index) => (
            <TableRow key={client.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>{TableHelper.viewIndex(props.pageIndex, index)}</TableCell>
              <TableCell>{client.id}</TableCell>
              <TableCell>{client.personalData.fullName}</TableCell>
              <TableCell>{client.email}</TableCell>
              <TableCell>{client.personalData.phone}</TableCell>
              <TableCell>{moment.unix(client.createdAt).format('DD.MM.YYYY')}</TableCell>
              <TableCell>{client.lastLoginDate ? moment.unix(client.lastLoginDate).format('DD.MM.YYYY') : ''}</TableCell>
              <TableCell>{getStatusNameByClientId(client.clientRankId, props.ranks)}</TableCell>
              <TableCell>

                <Grid container display='flex' columns={2} spacing={2} justifyContent="flex-end">
                  <Grid item xs={1}>
                    <ShowButton linkProps={{to: RouteList.CLIENTS_SHOW + '/' + client.id}} />
                  </Grid>
                  <Grid item xs={1} >
                    <DeleteButton disabled={isLoading} onClick={() => {
                      setClientId(client.id)
                      setShowConfirm(true)
                    }}/>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ClientsTabContentData;
